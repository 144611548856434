import { PolicyStatus } from '../dictionaries'
import { ClaimSubStatus } from '../dictionaries/ClaimSubStatus'
import { StatusEnum } from '../dictionaries/StatusEnum'
import { getEnumValues } from './getEnumValues'

export function claimSubStatusFromString(value: string | null): ClaimSubStatus | null {
  if (getEnumValues(StatusEnum.ClaimsSubStatus).includes(value as ClaimSubStatus)) {
    return value as ClaimSubStatus
  } else {
    return null
  }
}

export function policyStatusFromString(value: string): PolicyStatus | null {
  if (getEnumValues(StatusEnum.Policy).includes(value as PolicyStatus)) {
    return value as PolicyStatus
  } else {
    return null
  }
}
