import { TFunction } from 'react-i18next'

import { InputType } from '@alteos/ui'

import { ICardSection } from '../../../../../components/Cards'
import FieldType from '../../../../../dictionaries/FieldType'
import { IAction } from '../../../../../interfaces/store'
import { IUiConfig } from '../../../api/productConfigApi/productConfigDto'
import { SIDE_VIEW_ANIMATION_DURATION } from '../../../constants'
import { ModeType, PolicySideViewType } from '../../../dictionaries'
import { IGeneratePolicyHolderDetailsCards, IPolicy, ModalPayload } from '../../../interfaces'
import { generateEditHeaderCardForPolicyHolder } from '../../../utils'

const generatePolicyholderSideViewCards = (
  policy: IPolicy,
  config: IUiConfig,
  setModal: (payload: ModalPayload) => IAction<ModalPayload>,
  closeSideView: () => void,
  t: TFunction,
  shouldShowPolicyEdit: boolean
  // eslint-disable-next-line max-params
): IGeneratePolicyHolderDetailsCards => {
  const headerCard: ICardSection = {
    title: t('policyHolderDetails.header.title'),
    fields: [
      {
        label: t('policyHolderDetails.header.lastUpdatedAt'),
        id: 'updatedAt',
        formatFunction: 'formatDate',
        icon: 'calendar'
      },
      {
        type: FieldType.Button,
        children: t('common:buttons.edit'),
        size: 'full',
        color: 'outline',
        icon: 'pencil',
        hidden: !shouldShowPolicyEdit,
        handleClick: async () => {
          await setModal(null)
          await closeSideView()
          setTimeout(() => {
            setModal({
              type: PolicySideViewType.PolicyHolderDetails,
              mode: ModeType.Edit
            })
          }, SIDE_VIEW_ANIMATION_DURATION)
        }
      }
    ]
  }

  const editHeaderCard: ICardSection = generateEditHeaderCardForPolicyHolder(policy)

  if (typeof config.policyholderCards?.detailsCards !== 'undefined') {
    return {
      headerCard,
      editHeaderCard,
      cards: config.policyholderCards.detailsCards
    }
  }

  // TODO: refactor card to take name instead of id to remove duplication
  const cards: ICardSection[] = [
    {
      title: t('policyHolderDetails.personalInfo.title'),
      fields: [
        {
          label: t('policyHolderDetails.personalInfo.salutation'),
          id: 'customer.values.salutation',
          size: 'xsmall',
          editType: InputType.select,
          options: [
            {
              key: 'Herr',
              value: 'Herr'
            },
            {
              key: 'Frau',
              value: 'Frau'
            },
            {
              key: 'Divers',
              value: 'Divers'
            }
          ]
        },
        {
          label: t('policyHolderDetails.personalInfo.firstName'),
          id: 'customer.firstName',
          editType: InputType.string,
          size: 'small',
          required: true
        },
        {
          label: t('policyHolderDetails.personalInfo.lastName'),
          id: 'customer.lastName',
          editType: InputType.string,
          size: 'small',
          required: true
        },
        {
          label: t('policyHolderDetails.personalInfo.dateOfBirth'),
          id: 'customer.values.dateOfBirth',
          formatFunction: 'formatDate',
          icon: 'calendar',
          size: 'small',
          maximum: 'now',
          editType: InputType.date
        }
      ]
    },
    {
      title: t('policyHolderDetails.addressInfo.title'),
      fields: [
        {
          value: t('policyHolderDetails.addressInfo.homeAddress'),
          type: FieldType.Title,
          viewOnly: true
        },
        {
          label: t('policyHolderDetails.addressInfo.addressStreet'),
          id: 'customer.values.addressStreet',
          editType: InputType.string,
          size: 'large',
          // eslint-disable-next-line quotes
          regex: "^([.A-Za-z0-9- '`/À-ÖØ-öø-ÿ]*)$",
          minimum: 2,
          maximum: 50,
          required: true
        },
        {
          label: t('policyHolderDetails.addressInfo.addressHouseNumber'),
          id: 'customer.values.addressHouseNumber',
          editType: InputType.string,
          size: 'xsmall',
          regex: '^([A-Za-z0-9 /-]*)$',
          required: true
        },
        {
          label: t('policyHolderDetails.addressInfo.co'),
          id: 'customer.values.addressCo',
          editType: InputType.string,
          size: 'half',
          maximum: 30
        },
        {
          label: t('policyHolderDetails.addressInfo.plz'),
          id: 'customer.values.addressPlz',
          editType: InputType.string,
          size: 'half',
          maximum: 7,
          minimum: 4,
          regex: '^\\d{4}$|^\\d{5}$|^[0-9]{4}\\s?[A-Z]{2}$',
          required: true
        },
        {
          label: t('policyHolderDetails.addressInfo.city'),
          id: 'customer.values.addressCity',
          editType: InputType.string,
          regex: "^([A-Za-z-()./ À-ÖØ-öø-ÿ']*)$",
          minimum: 2,
          maximum: 50,
          size: 'half',
          required: true
        },
        {
          editType: InputType.string,
          label: t('policyHolderDetails.addressInfo.country'),
          // TODO: set proper id/value
          id: 'policyCustomer-country',
          disabled: true,
          size: 'half'
        }
      ]
    },
    {
      title: t('policyHolderDetails.contactInfo.title'),
      fields: [
        {
          label: t('policyHolderDetails.contactInfo.email'),
          id: 'customer.email',
          editType: InputType.email,
          maximum: 50,
          regex: 'email',
          required: true,
          size: 'half'
        },
        {
          label: t('policyHolderDetails.contactInfo.phone'),
          id: 'customer.phone',
          editType: InputType.string,
          minimum: 7,
          maximum: 18,
          regex: 'phone',
          size: 'half'
        }
      ]
    },
    {
      title: t('policyHolderDetails.accountInfo.title'),
      isStatic: true,
      fields: [
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.addressStreet'),
          id: 'account.values.addressStreet'
        },
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.addressHouseNumber'),
          id: 'account.values.addressHouseNumber'
        },
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.co'),
          id: 'account.values.addressCo'
        },
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.plz'),
          id: 'account.values.addressPlz'
        },
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.city'),
          id: 'account.values.addressCity'
        },
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.country'),
          // TODO: set proper id/value
          id: 'account-country'
        },
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.email'),
          id: 'account.email'
        },
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.phone'),
          id: 'account.phone'
        },
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.firstName'),
          id: 'account.firstName'
        },
        {
          viewOnly: true,
          label: t('policyHolderDetails.accountInfo.lastName'),
          id: 'account.lastName'
        },
        {
          id: 'helpers.applyChanges',
          editType: InputType.checkbox,
          editOnly: true,
          disabled: true,
          title: t('policyHolderDetails.form.applyChangesTitle'),
          label: t('policyHolderDetails.form.applyChanges'),
          required: true
        }
      ]
    }
  ]

  return {
    headerCard,
    editHeaderCard,
    cards
  }
}

export default generatePolicyholderSideViewCards
