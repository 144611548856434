import { FC } from 'react'

import { ITranslatable } from '@alteos/product-configuration'

import StatusItem from '../../../../components/Cards/Card/items/StatusItem'
import { useDynamicTranslation } from '../../../i18n/hooks'
import { ClaimStatusLabel } from './ClaimStatusLabel'

interface IProps {
  value: string
  label: ITranslatable | undefined
}

export const ClaimStatusItem: FC<IProps> = ({ value, label }: IProps) => {
  const translatedLabel: string = useDynamicTranslation(label)
  return (
    <StatusItem label={translatedLabel}>
      <ClaimStatusLabel status={value} />
    </StatusItem>
  )
}
