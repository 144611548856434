import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ClaimStatus } from '@alteos/claim-client/dist/dictionaries/ClaimStatus'

import Label from '../../../../components/Label'
import { LabelType } from '../../../../components/Label/dictionaries'
import { StatusEnum } from '../../../../dictionaries/StatusEnum'
import { getEnumValues } from '../../../../utils/getEnumValues'
import generateClaimLabelType from '../../SideViewContent/ClaimDetails/utils/generateClaimLabelType'

interface IProps {
  status: string | null
}

export const ClaimStatusLabel: FC<IProps> = ({ status }) => {
  const { t } = useTranslation('policy')
  const labelType: LabelType = generateClaimLabelType(status)
  const formattedValue = formatClaimStatus(status, t)
  return <Label type={labelType} content={formattedValue} dataTest="claim-status" mode="light" />
}

const formatClaimStatus = (status: string | null, t: (key: string) => string): string => {
  const isValidClaimStatus = getEnumValues(StatusEnum.Claims).includes(status as ClaimStatus)
  return isValidClaimStatus ? t(`claims.claimStatus.${status}`) : status || '-'
}
